
import Vue from "vue";
import { Mentor } from "@/types/interfaces";

export default Vue.extend({
  name: "ReportMentor",
  props: {
    mentor: {
      type: Object as () => Mentor
    }
  },
  data() {
    return {};
  }
});
